/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { Button } from '@components/Button';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { mergeStyles } from '@lib/styles';

export function ProductNav({
  links,
}: {
  links: { title: string; id: string }[];
}) {
  const {
    headerHeight: [headerHeight],
    headerScrollClass: [headerScrollClass],
  } = useContext<UIContextInterface>(UIContext);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [manualSelection, setmanualSelection] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(links[0].id); // default to first section

  const productNavRef = useRef<HTMLDivElement>(null);
  // have navbar stick to the header height or -1 depending on visibility
  // -1 to account for top border on nav bar to differentiate from mega menu, TODO remove when mega menu is removed
  const topPosition =
    headerScrollClass === 'hide' ? '-top-1' : `top-[${headerHeight}px]`;

  const getMenuSize = () => productNavRef.current?.scrollWidth || 0;

  const scrollToPosition = (
    element: HTMLElement | Window,
    left: number,
    top: number = 0
  ) => {
    element.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  };

  const updateArrowVisibility = () => {
    const nav = productNavRef.current;
    if (!nav) return;

    const totalWidth = getMenuSize();
    const visibleWidth = nav.offsetWidth;
    const scrollPosition = nav.scrollLeft;

    setShowLeftArrow(scrollPosition > 0);
    setShowRightArrow(totalWidth - visibleWidth - scrollPosition > 10);
  };

  const handleNavButtonClick = (direction: 'left' | 'right') => {
    const nav = productNavRef.current;
    if (!nav) return;

    const visibleWidth = nav.offsetWidth;
    const offset = direction === 'left' ? -visibleWidth : visibleWidth;
    scrollToPosition(nav, nav.scrollLeft + offset);
  };

  // used to auto scroll the nav bar to the correct section as the user scrolls
  // this helps when a nav bar section is hidden and a user has scrolled to that section
  // offset is for the arrows on the side
  const scrollNavToSection = (sectionId: string) => {
    const nav = productNavRef.current;
    if (!nav) return;

    const sectionLink = document.querySelector(
      `[data-id="${CSS.escape(sectionId)}-link"]`
    );

    if (sectionLink) {
      const { left: linkLeft } = sectionLink.getBoundingClientRect();
      const { left: navLeft } = nav.getBoundingClientRect();
      const scrollOffset = linkLeft - navLeft + nav.scrollLeft - 30;
      scrollToPosition(nav, scrollOffset);
    }
  };

  // handle horizontal grab and drag in browser
  const handleMouseDown = (e: MouseEvent) => {
    if (!productNavRef.current) return;

    setIsDragging(true);
    setStartX(e.pageX - productNavRef.current.offsetLeft);
    setScrollLeft(productNavRef.current.scrollLeft);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();

    if (!isDragging || !productNavRef.current) return;

    const x = e.pageX - productNavRef.current.offsetLeft;
    const walk = x - startX; // Distance dragged
    productNavRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsDragging(false);

  // handle vertical scroll to section of nav bar click
  const scrollToSection = (sectionId: string) => {
    setmanualSelection(true);
    setSelected(sectionId);

    const section = document.getElementById(sectionId);
    if (section) {
      const yPosition =
        section.getBoundingClientRect().top + window.scrollY - 30;

      scrollToPosition(window, 0, yPosition);
    }
  };

  useEffect(() => {
    // observe each section to be able to track which section the user is currently viewing
    // this is used to auto highlight the correct nav bar section as a user scrolls
    const observer = new IntersectionObserver(
      (entries) => {
        // find first entry so it's the top most visible section
        const visibleSection = entries.find((e) => e.isIntersecting)?.target.id;
        if (visibleSection && !manualSelection) setSelected(visibleSection);
      },
      { threshold: 0.1 } // trigger once .1 of section is visible
    );

    links.forEach((link) => {
      const section = document.getElementById(link.id);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, [links, manualSelection]);

  useEffect(() => {
    // need a timeout to allow time for auto scroll animation to complete without triggering the observer.
    // when multiple oberserved sections are in viewport, unexpected behaviours can happen with observer and manual selections
    // 1000 is for large section changes, takes time to scroll
    if (manualSelection) {
      const timeout = setTimeout(() => setmanualSelection(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [manualSelection]);

  useEffect(() => {
    // lastly, scroll nav bar so section is visible
    // handle independently so it happens immediately for both manual and scrolling selection
    // and not affected by the timeout
    if (selected) scrollNavToSection(selected);
  }, [selected]);

  useEffect(() => {
    const nav = productNavRef.current;
    nav?.addEventListener('scroll', () => updateArrowVisibility());
    window.addEventListener('resize', updateArrowVisibility);

    return () => {
      nav?.removeEventListener('scroll', () => updateArrowVisibility());
      window.removeEventListener('resize', updateArrowVisibility);
    };
  }, []);

  return (
    <div
      id="productNavBar"
      className={mergeStyles(
        topPosition, // top-[123px] (unified/mobile) top-[211px] (mega) -top-2 (hidden)
        'full-bleed sticky z-[998] min-h-[30px] border-t-2 border-white transition-all duration-200 ease-in-out dark:border-black'
      )}
    >
      <div
        ref={productNavRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        className="gae-product-nb horizontal-scroll overflow-x-auto whitespace-nowrap bg-grey-mid text-center text-sm dark:bg-grey-dark"
      >
        {links.map((link: { title: string; id: string }, i) => (
          <button
            type="button"
            onClick={() => scrollToSection(link.id)}
            data-id={`${link.id}-link`}
            key={`productnav-link-${i}`}
            aria-label={link.title}
            className={`${selected === link.id ? 'border-b-4 text-grey-darkest dark:text-grey-mid' : ''} xl:min-w-auto inline-block h-12 min-w-[100px] cursor-pointer border-green px-5 py-1.5 font-secondary text-lg font-bold uppercase leading-none tracking-wider hover:text-green`}
          >
            {link.title}
          </button>
        ))}
      </div>
      {showLeftArrow && (
        <Button
          tabIndex={-1}
          aria-label="Slide left"
          className="absolute bottom-0 left-0 top-0 h-12 w-6 bg-grey-mid hover:bg-black dark:bg-grey-dark
          hover:dark:bg-grey-mid hover:dark:text-white"
          onClick={() => handleNavButtonClick('left')}
          icon={<FiChevronLeft size="1.5rem" />}
        />
      )}
      {showRightArrow && (
        <Button
          tabIndex={-1}
          aria-label="Slide right"
          className="absolute bottom-0 right-0 top-0 h-12 w-6 bg-grey-mid hover:bg-black dark:bg-grey-dark
          hover:dark:bg-grey-mid hover:dark:text-white"
          onClick={() => handleNavButtonClick('right')}
          icon={<FiChevronRight size="1.5rem" />}
        />
      )}
    </div>
  );
}
